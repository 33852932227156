@import "../_partial/_single/toc";

.single {
    .single-title {
        margin: 2rem 0 .5rem;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 140%;
    }

    .single-subtitle {
        margin: .5rem 0;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: 100%;
    }

    .post-meta {
        font-size: .875rem;
        color: $global-font-secondary-color;

        span {
            display: inline-block;
        }

        [theme=dark] & {
            color: $global-font-secondary-color-dark;
        }

        @include link(false, true);

        .author {
            font-size: 1.05rem;
        }
    }

    .featured-image {
        margin: .5rem 0 1rem 0;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            overflow: hidden;
        }

        img.lazyloaded {
            width: 100%;
        }
    }

    .content {
        >h2 {
            font-size: 1.5rem;

            & code {
                font-size: 1.25rem;
            }
        }

        >h3 {
            font-size: 1.375rem;

            & code {
                font-size: 1.125rem;
            }
        }

        >h4 {
            font-size: 1.25rem;

            & code {
                font-size: 1rem;
            }
        }

        >h5 {
            font-size: 1.125rem;
        }

        >h6 {
            font-size: 1rem;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: bold;
            margin: 1.2rem 0;

            [theme=dark] & {
                font-weight: bolder;
            }
        }

        >h2,
        >h3,
        >h4,
        >h5,
        >h6 {
            >.header-mark::before {
                content: "|";
                margin-right: .3125rem;
                color: $single-link-color;

                [theme=dark] & {
                    color: $single-link-color-dark;
                }
            }
        }

        >h2>.header-mark::before {
            content: "#";
        }

        p {
            margin: .5rem 0;
        }

        b,
        strong {
            font-weight: bold;

            [theme=dark] & {
                color: #ddd;
            }
        }

        @include link(false, false);

        a {
            @include overflow-wrap(break-word);

            [theme=dark] & b,
            [theme=dark] & strong {
                color: $single-link-color-dark;
            }
        }

        [theme=dark] a:hover b,
        [theme=dark] a:hover strong {
            color: $single-link-hover-color-dark;
        }

        ul,
        ol {
            margin: .5rem 0;
            padding-left: 2.5rem;
        }

        ul {
            list-style-type: disc;
        }

        ruby {
            background: $code-background-color;

            rt {
                color: $global-font-secondary-color;
            }

            [theme=dark] & {
                background: $code-background-color-dark;

                rt {
                    color: $global-font-secondary-color-dark;
                }
            }
        }

        .table-wrapper {
            overflow-x: auto;

            &::-webkit-scrollbar {
                background-color: $table-background-color;

                [theme=dark] & {
                    background-color: $table-background-color-dark;
                }
            }

            >table {
                width: 100%;
                max-width: 100%;
                margin: .625rem 0;
                border-spacing: 0;
                background: $table-background-color;
                border-collapse: collapse;

                [theme=dark] & {
                    background: $table-background-color-dark;
                }

                thead {
                    background: $table-thead-color;

                    [theme=dark] & {
                        background-color: $table-thead-color-dark;
                    }
                }

                th,
                td {
                    padding: .3rem 1rem;
                    border: 1px solid darken($table-thead-color, 2%);

                    [theme=dark] & {
                        border-color: darken($table-thead-color-dark, 2%);
                    }
                }
            }
        }

        img {
            max-width: 100%;
            min-height: 1em;
        }

        figure {
            margin: .5rem;
            text-align: center;

            .image-caption:not(:empty) {
                min-width: 20%;
                max-width: 80%;
                display: inline-block;
                padding: .5rem;
                margin: 0 auto;
                font-size: .875rem;
                color: #969696;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                margin: 0 auto;
                overflow: hidden;
            }
        }

        .lazyload,
        .lazyloading {
            @include object-fit(scale-down);
        }

        .lazyloaded {
            @include object-fit(fill);
        }

        blockquote {
            display: block;
            border-left: .5rem solid $blockquote-color;
            background-color: rgba($blockquote-color, .2);
            padding: .25rem .75rem;
            margin: 1rem 0;

            [theme=dark] & {
                border-left-color: $blockquote-color-dark;
                background-color: rgba($blockquote-color-dark, .2);
            }
        }

        .footnotes {
            color: $global-font-secondary-color;

            [theme=dark] & {
                color: $global-font-secondary-color-dark;
            }

            p {
                margin: .25rem 0;
            }
        }

        @import "../_partial/_single/code";
        @import "../_partial/_single/instagram";
        @import "../_partial/_single/admonition";
        @import "../_partial/_single/echarts";
        @import "../_partial/_single/mapbox";
        @import "../_partial/_single/music";
        @import "../_partial/_single/bilibili";

        hr {
            margin: 1rem 0;
            position: relative;
            border-top: 1px dashed $global-border-color;
            border-bottom: none;

            [theme=dark] & {
                border-top: 1px dashed $global-border-color-dark;
            }
        }

        kbd {
            display: inline-block;
            padding: .25rem;
            background-color: $global-background-color;
            border: 1px solid $global-border-color;
            border-bottom-color: $global-border-color;
            @include border-radius(3px);
            @include box-shadow(inset 0 -1px 0 $global-border-color);
            font-size: .8rem;
            font-family: $code-font-family;
            color: $code-color;

            [theme=dark] & {
                background-color: $global-background-color-dark;
                border: 1px solid $global-border-color-dark;
                border-bottom-color: $global-border-color-dark;
                @include box-shadow(inset 0 -1px 0 $global-border-color-dark);
                color: $code-color-dark;
            }
        }

        .version {
            height: 1.25em;
            vertical-align: text-bottom;
        }
    }

    @import "../_partial/_single/footer";
    @import "../_partial/_single/comment";
}

.typeit {
    .highlight {
        padding: .375rem;
        font-size: .875rem;
        font-family: $code-font-family;
        font-weight: bold;
        word-break: break-all;
        white-space: pre-wrap;
    }

    --ti-cursor-font-family: $global-font-family;
    --ti-cursor-font-size: $global-font-size;
    --ti-cursor-font-weight: $global-font-weight;
    --ti-cursor-line-height: $global-line-height;
    --ti-cursor-color: $global-font-secondary-color;
    --ti-cursor-margin-left: 0;

    [theme=dark] & {
        --ti-cursor-color: $global-font-secondary-color-dark;
    }
}

.lg-toolbar .lg-icon::after {
    color: #999;
}
