.page {
    position: relative;
    max-width: 900px;
    width: 80%;
    margin: 0 auto;

    [data-header-desktop] & {
        padding-top: $header-height;
    }

    [data-header-desktop=normal] & {
        padding-top: 0;
    }

    @include blur;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";
