.post-footer {
    margin-top: 3rem;

    .post-info {
        border-bottom: 1px solid $global-border-color;
        padding: 1rem 0 0.3rem;

        [theme=dark] & {
            border-bottom: 1px solid $global-border-color-dark;
        }

        .post-info-line {
            display: flex;
            justify-content: space-between;

            .post-info-mod {
                font-size: 0.8em;
                color: $global-font-secondary-color;

                [theme=dark] & {
                    color: $global-font-secondary-color-dark;
                }

                @include link(false, false);
            }

            .post-info-license {
                font-size: 0.8em;
                color: $global-font-secondary-color;

                [theme=dark] & {
                    color: $global-font-secondary-color-dark;
                }

                @include link(false, false);
            }

            .post-info-md {
                font-size: 0.8rem;
                width: 8rem;

                @include link(false, false);
            }

            .post-info-share {
                a * {
                    vertical-align: text-bottom;
                }
            }
        }
    }

    .post-info-more {
        padding: 0.3rem 0 1rem;
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
    }

    .post-tags {
        max-width: 85%;

        * {
            display: inline;
        }
    }

    .post-nav {

        &::before,
        &::after {
            content: ' ';
            display: table;
        }

        & a.prev,
        & a.next {
            font-size: 1rem;
            font-weight: 600;
            @include transition(all 0.3s ease-out);
        }

        & a.prev {
            float: left;
        }

        & a.prev:hover {
            @include transform(translateX(-4px));
        }

        & a.next {
            float: right;
        }

        & a.next:hover {
            @include transform(translateX(4px));
        }
    }
}
